import React from 'react';

const Footer = () => {
	const getDate = () => {
		const d = new Date();
		return d.getFullYear();
	};
	return (
		<section className="">
			<footer className="text-white text-center" style={{ backgroundColor: '#1B1E23' }}>
				<div className="container p-4">
					<div className="row my-4">
						<div className="col-lg-6 col-md-12 mb-4 mb-md-0">
							<h5 className="text-uppercase">Address</h5>
							<a href ="https://www.google.com/maps/place/ASkey+Auto/@19.2077402,72.8347933,17z/data=!3m1!4b1!4m6!3m5!1s0x3be7b6cf85555555:0x9787f6b79da426c6!8m2!3d19.2077402!4d72.8373682!16s%2Fg%2F12hpqnkql?entry=ttu">Address: 201 2nd Floor Hinal Residency, Dahanukar Wadi, <br />
							Kandivli West, Mumbai, Maharashtra - 400067</a>
						</div>

						<div className="col-lg-3 col-md-6 mb-4 mb-md-0">
							<h5 className="text-uppercase">Phone</h5>
							<a href="tel:+919820033356">+91 9820033356</a>
						</div>

						<div className="col-lg-3 col-md-6 mb-4 mb-md-0">
							<h5 className="text-uppercase mb-0">Email</h5>
							<a href="mailto:askeyauto@gmail.com">askeyauto@gmail.com</a>
						</div>
					</div>
				</div>

				<div className="text-center p-3" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
					© {getDate()} Askey Autos
					<br />
					Made by Ansh Mehta and Charmil Shah
				</div>
			</footer>
		</section>
	);
};

export default Footer;
