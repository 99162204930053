import React from 'react';
import Logo from 'assets/Backup_of_logo.png';
import { NavLink } from 'react-router-dom';

const Header = () => {
	return (
		<>
			{/* <nav className="navbar navbar-expand-lg navbar-light bg-light">
				<div className="container container-fluid">
					<NavLink className="navbar-brand" to="/">
						<img id="navbarLogo" src={Logo} alt="brand logo" style={{ marginLeft: '5vw' }} />
					</NavLink>
					<button
						className="navbar-toggler"
						type="button"
						data-bs-toggle="collapse"
						data-bs-target="#navbarTogglerDemo02"
						aria-controls="navbarTogglerDemo02"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className="collapse navbar-collapse" id="navbarTogglerDemo02">
						<ul className="d-flex flex-end navbar-nav ms-auto mb-2 mb-lg-0" style={{ marginRight: '5vw' }}>
							<li className="nav-item">
								<NavLink className="nav-link active" aria-current="page" to="/">
									Home
								</NavLink>
							</li>
							<li className="nav-item">
								<NavLink className="nav-link" to="/catalogue">
									Catalogue
								</NavLink>
							</li>
							<li className="nav-item">
								<NavLink className="nav-link" to="/contactUs">
									Contact Us
								</NavLink>
							</li>
						</ul>
					</div>
				</div>
			</nav> */}
			<nav className="navbar navbar-expand-lg navbar-light  shadow-sm" id="mainNav">
				<div className="container px-5">
					<NavLink className="navbar-brand" to="/">
						<img id="navbarLogo" src={Logo} alt="brand logo" />
					</NavLink>
					<button
						className="navbar-toggler collapsed"
						type="button"
						data-bs-toggle="collapse"
						data-bs-target="#navbarResponsive"
						aria-controls="navbarResponsive"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						Menu
						<i className="bi-list"></i>
					</button>
					<div className="navbar-collapse collapse" id="navbarResponsive">
						<ul className="d-flex flex-end navbar-nav ms-auto mb-2 mb-lg-0" style={{ marginRight: '5vw' }}>
							<li className="nav-item">
								<NavLink className="nav-link active" aria-current="page" to="/">
									Home
								</NavLink>
							</li>
							<li className="nav-item">
								<NavLink className="nav-link" to="/catalogue">
									Catalogue
								</NavLink>
							</li>
							<li className="nav-item">
								<NavLink className="nav-link" to="/contactUs">
									Contact Us
								</NavLink>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</>
	);
};

export default Header;
