import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import 'styles/App.css';

import Header from 'components/Header.js';
import Footer from 'components/Footer.js';

const HomePage = lazy(() => import('pages/HomePage.js'));
const ManageCars = lazy(() => import('pages/admin/ManageCars.js'));
const AddNewCar = lazy(() => import('pages/admin/AddNewCar'));
const Catalogue = lazy(() => import('pages/Catalogue.js'));
const CarInfo = lazy(() => import('pages/CarInfo'));
const ContactUs = lazy(() => import('pages/ContactUs'));
const NotFound = lazy(() => import('pages/NotFound.js'));

const App = () => {
	const LoaderComponent = () => {
		return (
			<div className="d-flex justify-content-center" style={{ marginTop: '50vh' }}>
				<div className="spinner-border" role="status">
					<span className="visually-hidden">Loading...</span>
				</div>
			</div>
		);
	};
	return (
		<Suspense fallback={<LoaderComponent />}>
			<BrowserRouter>
				<Header />
				<Toaster />
				<div>
					<Routes>
						<Route path="/" element={<HomePage />} />
						<Route path="/admin" element={<ManageCars />} />
						<Route path="/admin/new" element={<AddNewCar />} />
						<Route path="/catalogue" element={<Catalogue />} />
						<Route path="/cars/:name" element={<CarInfo />} />
						<Route path="/contactUs" element={<ContactUs />} />
						<Route path="/404NotFound" element={<NotFound />} />
						<Route path="*" element={<Navigate to="/404NotFound" />} />
					</Routes>
				</div>
				<Footer />
			</BrowserRouter>
		</Suspense>
	);
};

export default App;
